import React from 'react';

export default class Hero extends React.Component {
  render() {
    return (
      <section className='hero hero-bg is-large'>
        <div className='hero-body'></div>
        <div className='hero-bottom'>
          <div className='hero-left'>
            <p>
              Optometry offices providing eye care services for Antioch,
              Brentwood, Oakley and surrounding areas for more than 30 years.
            </p>
          </div>
          <div className='hero-right'>
            <p className='hero-address'>
              We moved!
              <br />
              5109 Lone Tree Way, Antioch, CA 94531
              <br />
              925-757-5560
            </p>
            {/*<a target="_blank" rel="noopener noreferrer" href="https://www.4patientcare.ws/v5dn/ws/wsv3p.aspx?CoverKey=3415&LocKey=5988&Source=website&ReferredBy=website" className="button is-info is-inverted is-outlined is-large">Schedule an Appointment</a> */}
          </div>
        </div>
      </section>
    );
  }
}
