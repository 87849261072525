import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: '',
    };
  }

  componentWillMount() {
    axios
      .get(
        'https://public-api.wordpress.com/wp/v2/sites/fovcadmin.wordpress.com/pages?slug=home-hours'
      )
      .then((res) => {
        const hours = res.data[0].content;
        this.setState({ hours });
      });
  }

  render() {
    return (
      <section className='footer' id='footer'>
        <div className='container'>
          <div className='footer-left'>
            <p className='footer-title'>Family Optometric Vision Care</p>
            <p className='footer-address-phone footer-content'>
              5109 Lone Tree Way, Antioch, CA 94531
              <br />
              925-757-5560
            </p>
            <div className='hours'>
              <div
                className='days'
                dangerouslySetInnerHTML={{ __html: this.state.hours.rendered }}
              ></div>
            </div>
            <div className='hours'>
              <p className='daysweclosed'>
                Regular Office Hours.
                <br />
                More info including special dates closed at{' '}
                <Link
                  bold='true'
                  to='/hours-location'
                  style={{ color: '#BBB' }}
                >
                  Hours & Location
                </Link>
              </p>
            </div>

            <div className='footer-social'>
              <span className='icon'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.facebook.com/familyoptometric/'
                >
                  <i className='fab fa-facebook-square'></i>
                </a>
              </span>
              <span className='icon'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.instagram.com/familyoptovc/'
                >
                  <i className='fab fa-instagram'></i>
                </a>
              </span>
              <span className='icon'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.yelp.com/biz/family-optometric-vision-care-antioch'
                >
                  <i className='fab fa-yelp'></i>
                </a>
              </span>
            </div>

            <p className='copyright'>
              Copyright © 2020 Family Optometric Vision Care.{' '}
              <Link to='/privacy-policy'>Privacy Policy</Link>. Site by{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='http://www.wrlowe.com'
              >
                wrlowe.com
              </a>
              .
            </p>
          </div>
          <div className='footer-right'>
            <Link to='/'>Home</Link>
            <br />
            <Link to='/'>Our Services</Link>
            <br />
            <Link to='/'>Our Office</Link>
            <br />
            <Link to='/team'>Our Staff</Link>
            <br />
            <Link to='eyeglasses-contacts'>Eyeglasses & Contacts</Link>
            <br />
            <Link to='insurance'>Insurance</Link>
            <br />
            <Link to='hours-location'>Hours & Location</Link>
            <br />
            <Link to='contact-us'>Contact Us</Link>
            <br />
          </div>
        </div>
      </section>
    );
  }
}
