import React, { Component } from 'react';
import { Route, Link, withRouter, Switch } from "react-router-dom";
import 'bulma/css/bulma.css'
import './App.css';


import Nav from './comp/Navbar';
import Footer from './comp/Footer';

//Routes
import Home from './comp/routes/Home';
import ServicePost from './comp/routes/ServicePost';
import ContentPost from './comp/routes/ContentPost';
import NoMatch from './comp/routes/NoMatch';
import Survey from './comp/routes/Survey';


const Navbar = withRouter(props => <Nav {...props}/>);

class App extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <Switch>
          <Route exact path="/" component={Home} />    
          <Route exact path="/404" component={NoMatch} />
          <Route exact path="/service/:postid" component={ServicePost} />
          <Route path="/:postid" component={ContentPost} />
        </Switch>
        <Footer/>
      </div>
    );
  }
}

export default App;
