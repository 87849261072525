import React from 'react';

import Hero from '../Hero';
import EyeActions from '../EyeActions';
import HomeContent from '../HomeContent';
import EyeServices from '../EyeServices';

export default class Home extends React.Component{
    render(){
        return(
            <div className="home-container">
                <Hero/>
                <EyeActions/>
                <HomeContent/>
                <EyeServices/>
            </div>
        )
    }
}