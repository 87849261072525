import React from 'react';
import TweenLite from 'gsap';
import scrollTo from 'gsap/ScrollToPlugin.js';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import $ from 'jquery';

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOn: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.disableMenu = this.disableMenu.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  toggleMenu() {
    var tState = !this.state.menuOn;
    this.setState({
      menuOn: tState,
    });
    $('html').toggleClass('menud');
  }

  disableMenu() {
    this.setState({
      menuOn: false,
    });
    $('html').removeClass('menud');
  }

  scrollTo(divv) {
    TweenLite.to(window, 0.5, { scrollTo: divv });
  }

  render() {
    return (
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a
            role='button'
            className='navbar-burger'
            aria-label='menu'
            aria-expanded='false'
            onClick={this.toggleMenu}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
          <Link className='navbar-item nav-title-cont' to='/'>
            <span className='nav-title'>Family Optometric Vision Care</span>
          </Link>
        </div>
        <div className='navbar-menu'>
          <div className='navbar-end'>
            <div className='navbar-item'>
              <p>
                5109 Lone Tree Way, Antioch, CA 94531
                <br />
                <a href='tel:+19257575560'>925-757-5560</a>
                <br />
                {/*false ? null :<a rel="noopener noreferrer" target="_blank" href="https://www.4patientcare.ws/v5dn/ws/wsv3p.aspx?CoverKey=3415&LocKey=5988&Source=website&ReferredBy=website" className="button is-danger is-outlined">Schedule an Appointment</a> */}
              </p>
            </div>
          </div>
        </div>
        <div
          className={'hero-menu ' + (this.state.menuOn ? '' : 'hero-inactive')}
        >
          <Link
            onClick={() => this.disableMenu()}
            to='/'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/' ? 'hero-active' : '')
            }
          >
            <div className='hero-menu-item hero-home'>
              <p>Home</p>
            </div>
          </Link>
          <Link
            onClick={() => {
              this.scrollTo('#office');
              this.disableMenu();
            }}
            to='/'
          >
            <div className='hero-menu-item'>
              <p>Our Office</p>
            </div>
          </Link>
          <Link
            onClick={() => {
              this.scrollTo('#services');
              this.disableMenu();
            }}
            to='/'
          >
            <div className='hero-menu-item'>
              <p>Our Services</p>
            </div>
          </Link>
          <Link
            onClick={() => this.disableMenu()}
            to='/team'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/team' ? 'hero-active' : '')
            }
          >
            <div className='hero-menu-item'>
              <p>Our Team</p>
            </div>
          </Link>
          <Link
            onClick={() => this.disableMenu()}
            to='/eyeglasses-contacts'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/eyeglasses-contacts'
                ? 'hero-active'
                : '')
            }
          >
            <div className='hero-menu-item'>
              <p>Eyeglasses & Contacts</p>
            </div>
          </Link>
          <Link
            onClick={() => this.disableMenu()}
            to='/insurance'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/insurance'
                ? 'hero-active'
                : '')
            }
          >
            <div className='hero-menu-item'>
              <p>Insurance</p>
            </div>
          </Link>
          <Link
            onClick={() => this.disableMenu()}
            to='/hours-location'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/hours-location'
                ? 'hero-active'
                : '')
            }
          >
            <div className='hero-menu-item'>
              <p>Hours & Location</p>
            </div>
          </Link>
          <Link
            onClick={() => this.disableMenu()}
            to='/contact-us'
            className={
              'hero-home ' +
              (this.props.location.pathname == '/contact-us'
                ? 'hero-active'
                : '')
            }
          >
            <div className='hero-menu-item'>
              <p>Contact Us</p>
            </div>
          </Link>
        </div>
      </nav>
    );
  }
}
