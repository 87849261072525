import React from 'react';





export default class ContentPost extends React.Component{
    constructor(props){
        super(props);

        this.state = {
        }
    }



    render(){
        return(
            <div className="content-container">
                {this.state.redirect}
                <section className="content-image"></section>
                <div className="content-cont">
                <script> var repuso_floating_id = 6219; var website_id = 236; </script>
<script type="text/javascript" src="https://repuso.com/widgets/floating.js" async></script>

                </div>
            </div>
        )
    }
}