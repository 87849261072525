import React from 'react';

import { Link } from 'react-router-dom';

export default class NoMatch extends React.Component{
    render(){
        return(
            <div className="no-match">
                <p className="no-match-head">
                    404
                </p>   
                <p className="no-match-content">
                    Uh oh, that page isn't found!<br/>Try going back <Link to="/">Home</Link>.
                </p>
            </div>
        )
    }
}