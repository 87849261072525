import React from 'react';

export default class HomeContent extends React.Component {
  render() {
    return (
      <section className='home-content' id='office'>
        <div className='home-left'>
          <p className='home-header'>
            Looking for an Optometrist in Antioch, CA?
          </p>
          <p className='home-header'>
            Welcome to{' '}
            <span className='home-title'>Family Optometric Vision Care.</span>
          </p>
          <br />
          <br />
          <p className='home-sub'>
            Visit us at our Antioch office at 5109 Lone Tree Way, Antioch, CA
            94531
          </p>
          <br />
          <br />
          <p className='home-desc'>
            Our experienced optometrists provide comprehensive eye exams and
            detailed advice on how to keep your eyes healthy. We also have one
            of the area’s largest optical departments, with a great selection of
            frames, sunglasses and contact lenses for every budget.
            <br />
            <br />
            Call us at <a href='tel:+19257575560'>925-757-5560</a>.
          </p>
        </div>
        <div className='home-right'>
          <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.6368205924637!2d-121.75780904873615!3d37.96226520902291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808ff88fb11638e1%3A0x3863dfc2c597dac6!2sFamily+Optometric+Vision+Care!5e0!3m2!1sen!2sus!4v1526066399454' />
        </div>
      </section>
    );
  }
}
