import React from 'react';
import { Link } from 'react-router-dom';

export default class EyeActions extends React.Component {
  render() {
    return (
      <section className='section eye-actions'>
        <div className='eye-action patient-form'>
          <Link to='/patient-forms'>
            <span className='icon'>
              <i className='far fa-clipboard'></i>
            </span>
            <p>PATIENT HISTORY FORMS</p>
          </Link>
        </div>
        <div className='eye-action read-reviews'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://www.yelp.com/biz/family-optometric-vision-care-antioch'
          >
            <span className='icon'>
              <i className='fas fa-star'></i>
            </span>
            <p>READ OUR REVIEWS</p>
          </a>
        </div>
        <div className='eye-action pay-bill'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://www.carecredit.com/apply/confirm.html?encm=AWALMlY0VDEBPQJmUWUBaAQ-XzkKbwc3BWdSYVU8U2A&?gemid1=B3CALAdToolkitANCard'
          >
            <span className='icon'>
              <i className='far fa-credit-card'></i>
            </span>
            <p>PAY YOUR BILL</p>
          </a>
        </div>
        <div className='eye-action order-contacts'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://secure.yourlens.com/Store/'
          >
            <span className='icon'>
              <i className='far fa-eye'></i>
            </span>
            <p>ORDER CONTACT LENSES</p>
          </a>
        </div>
      </section>
    );
  }
}
