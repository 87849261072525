import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';

class ScrollTop extends React.Component{
    componentDidUpdate(prevProps){
        window.scrollTo(0,0)
    }
    render() {
        return this.props.children
    }
}


ReactDOM.render(<BrowserRouter><ScrollTop><App /></ScrollTop></BrowserRouter>, document.getElementById('root'));
registerServiceWorker();
