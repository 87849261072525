import React from 'react';
import axios from 'axios';

import EyeService from './EyeService';

export default class EyeServices extends React.Component{
    constructor(props){
        super(props);
        
        this.state ={
            services: []
        }

    }

    componentWillMount(){
        axios.get("https://public-api.wordpress.com/wp/v2/sites/fovcadmin.wordpress.com/posts").then(res =>{
            const services = res.data;
            this.setState({ services });
        })
    }


    render(){
        return(
            <section className="eye-services" id="services">
            <p className="our-services">
                    OUR SERVICES
                </p>
                <div className="container">
                
                {this.state.services.reverse().map(serv =>
                    <EyeService service={serv} key={serv.id}/>
                )}
                </div>
            </section>
        )
    }
}