import React from 'react';
import axios from 'axios';

import { Redirect } from 'react-router-dom';




export default class ServicePost extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            postid: this.props.match.params.postid,
            post : {},
            content : {rendered : ""},
            title: "",
            redirect : ""
        }
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps;
        this.getPost = this.getPost.bind(this);
    }

    getPost(){
        axios.get("https://public-api.wordpress.com/wp/v2/sites/fovcadmin.wordpress.com/posts").then(res =>{
            var found = false;
            for(var i=0; i < res.data.length; i++){
                if(res.data[i].slug == this.props.match.params.postid){
                    found = true;
                    this.setState({
                        post: res.data[i],
                        content: res.data[i].content,
                        title: res.data[i].title
                    })
                }
            }
            if(!found){
                this.setState({
                    redirect : <Redirect to='/404' />
                }) 
            }
        })
    }

    componentDidMount(){
        this.getPost()
    }

    componentWillReceiveProps(prevprops){
        if(this.props.match.params.postid !== prevprops.match.params.postid){
            this.getPost();
        }
    }





    render(){
        return(
            <div className="content-container">
            {this.state.redirect}
                <section className="content-image"></section>
                <div className="content-cont">
                    <div className="content-title" dangerouslySetInnerHTML={{__html:this.state.title.rendered}}>
                    </div>
                    <div className="content-html" dangerouslySetInnerHTML={{__html:this.state.content.rendered}} > 
                    </div>
                </div>
            </div>
        )
    }
}