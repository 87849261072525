import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'


export default class EyeService extends React.Component{
    render(){
        return(
            <div className="eye-service box">
                <Link to={"/service/" + this.props.service.slug}>
                <div className="media">
                    <div className="media-content">
                        <p className="service-head" dangerouslySetInnerHTML={{__html: this.props.service.title.rendered}}>
                        </p>
                        <p className="service-content" dangerouslySetInnerHTML={{__html: this.props.service.excerpt.rendered}}>
                        </p>
                    </div>
                </div>
                </Link>
            </div>
        )
    }
}